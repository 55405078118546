<script lang="ts">
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
  OzPlainButtonHrefMode as OzTabsWithSeparatorHrefMode,
} from '@@/library/v4/components/OzPlainButton.vue'
import OzTooltip from '@@/library/v4/components/OzTooltip.vue'
import type { VueCssClass } from '@@/types'
import tinykeys from 'tinykeys'
import type { ComponentPublicInstance } from 'vue'
import { computed, onBeforeUnmount, onMounted, ref, withDefaults } from 'vue'

interface OzTabsWithSeparatorButtonProps {
  text: string
  key: string
  ariaControls?: string
  href?: string
  hrefMode?: OzTabsWithSeparatorHrefMode
  disabled?: boolean
  /**
   * Id for querying tab in tests
   */
  testId?: string
  colorPreset?: OzTabsWithSeparatorButtonColorPreset
  tooltipText?: string
  xTitleTooltip?: boolean
  startAdornmentText?: string
}

enum OzTabsWithSeparatorAccessibilityRole {
  Tabs = 'Tabs',
  Navigation = 'Navigation',
}

enum OzTabsWithSeparatorSizePreset {
  H28px = 'H28px',
  H37px = 'H37px',
  H40px = 'H40px',
  H42px = 'H42px',
  H48px = 'H48px',
  H59px = 'H59px',
}

enum OzTabsWithSeparatorButtonColorPreset {
  Primary = 'Primary',
}

export {
  OzTabsWithSeparatorAccessibilityRole,
  OzTabsWithSeparatorButtonColorPreset,
  OzTabsWithSeparatorHrefMode,
  OzTabsWithSeparatorSizePreset,
}
export type { OzTabsWithSeparatorButtonProps }
export default {}
</script>

<script lang="ts" setup>
// Defining props
const props = withDefaults(
  defineProps<{
    /**
     * Label corresponding to the tab list.
     */
    ariaLabel: string
    /**
     * Tabs to display.
     */
    tabs: OzTabsWithSeparatorButtonProps[]
    /**
     * Key used to detect the current tab.
     */
    currentTabKey?: string
    /**
     * Applies preset sizes/shapes.
     * They are named with pixel values for developer ergonomics -- those are the values you see in the mockups.
     * Technically, the pixel value could vary depending on the size of `rem`.
     */
    sizePreset?: OzTabsWithSeparatorSizePreset
    /**
     * Accommodates the elements and attributes according to the use.
     * If `Tabs`, the component will be structured according to the ARIA tab list specification.
     * If `Navigation`, the component will be structured according to the ARIA navigation specification.
     */
    accessibilityRole?: OzTabsWithSeparatorAccessibilityRole
    /**
     * Dark mode.
     */
    darkMode?: boolean | 'auto'
    /**
     * Classes applied to the tabs container.
     */
    tabsContainerClasses?: string | undefined
    /**
     * Classes applied to the non-disabled tab button.
     */
    nonDisabledButtonClasses?: string | undefined
    /**
     * Classes applied to the selected tab button.
     */
    selectedTextColorClasses?: VueCssClass | undefined
    /**
     * Classes applied to the non-selected tab button.
     */
    nonSelectedTextColorClasses?: VueCssClass | undefined
  }>(),
  {
    accessibilityRole: OzTabsWithSeparatorAccessibilityRole.Tabs,
    darkMode: 'auto',
    sizePreset: OzTabsWithSeparatorSizePreset.H42px,
    tabsContainerClasses: undefined,
    nonDisabledButtonClasses: undefined,
    selectedTextColorClasses: undefined,
    nonSelectedTextColorClasses: undefined,
    currentTabKey: undefined,
  },
)

const emit = defineEmits<{
  (
    e: 'activate-tab',
    event: Event,
    tab: {
      key: string
    },
  ): void
}>()

interface Tab {
  key: string
  el: HTMLElement
}
const list = ref<HTMLUListElement>()
const tabButtonElsRef = ref<InstanceType<typeof OzPlainButton>[]>([])
const currentTabId = computed(() => props.currentTabKey || props.tabs[0].key)
const tabsRef = ref<Array<Tab | null>>(Array(props.tabs.length).fill(null))

const setTabRef = (
  el: Element | ComponentPublicInstance | null,
  index: number,
  tab: OzTabsWithSeparatorButtonProps,
) => {
  if (el === null) return
  tabsRef.value[index] = { key: tab.key, el: el as HTMLElement }
}

const wrapperElement = computed(() => {
  return props.accessibilityRole === OzTabsWithSeparatorAccessibilityRole.Tabs ? 'div' : 'nav'
})

const wrapperAttributes = computed(() => {
  return props.accessibilityRole === OzTabsWithSeparatorAccessibilityRole.Tabs ? {} : { 'aria-label': props.ariaLabel }
})

const listAttributes = computed(() => {
  return isUsingTabsAccessibilityRole.value ? { 'aria-label': props.ariaLabel } : {}
})

const listRole = computed(() => {
  return isUsingTabsAccessibilityRole.value ? 'tablist' : 'list'
})

const listItemRole = computed(() => {
  return isUsingTabsAccessibilityRole.value ? 'presentation' : 'listitem'
})

const isUsingTabsAccessibilityRole = computed(() => {
  return props.accessibilityRole === OzTabsWithSeparatorAccessibilityRole.Tabs
})

function computeTabAttributes(tab: OzTabsWithSeparatorButtonProps): Record<string, any> {
  return isUsingTabsAccessibilityRole.value
    ? {
        id: tab.key,
        role: 'tab',
        'aria-controls': tab.ariaControls,
        'aria-selected': currentTabId.value === tab.key ? 'true' : 'false',
        tabindex: currentTabId.value === tab.key ? 0 : -1,
      }
    : {
        role: tab.href ? 'link' : 'button',
      }
}

/** Selected tab text underline classes */
/**
 * Instead of using text-decoration-line: underline, we use a pseudo-element to style the underline.
 * This is because there are instances where the text within a tab is made up of multiple elements,
 * causing the underline to be broken if we use text-decoration-line: underline.
 */
const highContrastSelectedTabUnderlineClasses = computed(() => [
  'relative',
  "high-contrast:after:content-['']",
  'high-contrast:after:absolute',
  'high-contrast:after:h-px',
  'high-contrast:after:mx-3',
  'high-contrast:after:inset-x-0',
  'high-contrast:after:bottom-1',
  {
    'high-contrast:after:bg-grape-500': props.darkMode === false,
    'high-contrast:after:bg-canary-500': props.darkMode === true,
    'high-contrast:after:bg-grape-500 dark:high-contrast:after:bg-canary-500': props.darkMode === 'auto',
  },
])

// #region Keyboard navigation
/**
 * Focus the first tab.
 */
function focusFirstTab(): void {
  if (tabsRef.value.length === 0) return
  const el = tabsRef.value[0]?.el.firstChild as HTMLElement
  el.focus()
}

/**
 * Focus the last tab.
 */
function focusLastTab(): void {
  if (tabsRef.value.length === 0) return
  const lastTabEl = tabsRef.value[tabsRef.value.length - 1]
  if (lastTabEl !== null) {
    const el = lastTabEl.el.firstChild as HTMLElement
    el.focus()
  }
}

/**
 * When pressing "Home", focus the first tab.
 */
function handleHomeKey(event: KeyboardEvent): void {
  event.preventDefault()
  focusFirstTab()
}

/**
 * When pressing "End", focus the last tab.
 */
function handleEndKey(event: KeyboardEvent): void {
  event.preventDefault()
  focusLastTab()
}

/**
 * When pressing the left arrow, focus the previous tab.
 * If on first tab, focus the last tab.
 */
function handleArrowLeft(event: KeyboardEvent): void {
  const target = event.target as HTMLElement
  const wrapper = target.parentElement

  // Skip the separator
  if (wrapper?.previousElementSibling?.previousElementSibling) {
    const previousSibling = wrapper.previousElementSibling.previousElementSibling.firstChild as HTMLElement
    previousSibling?.focus()
  } else {
    focusLastTab()
  }
}

/**
 * When pressing the right arrow, focus the next tab.
 * If on last tab, focus the first tab.
 */
function handleArrowRight(event: KeyboardEvent): void {
  const target = event.target as HTMLElement
  const wrapper = target.parentElement

  // Skip the separator
  if (wrapper?.nextElementSibling?.nextElementSibling) {
    const nextSibling = wrapper.nextElementSibling.nextElementSibling.firstChild as HTMLElement
    nextSibling?.focus()
  } else {
    focusFirstTab()
  }
}

let removeKeyboardShortcutsListener = () => {}

onMounted(() => {
  if (list.value) {
    removeKeyboardShortcutsListener = tinykeys(list.value, {
      Home: handleHomeKey,
      End: handleEndKey,
      ArrowLeft: handleArrowLeft,
      ArrowRight: handleArrowRight,
    })
  }
})

onBeforeUnmount(() => {
  removeKeyboardShortcutsListener()
})
// #endregion
</script>

<template>
  <component :is="wrapperElement" class="relative" v-bind="wrapperAttributes">
    <ul
      ref="list"
      :class="[
        'list-none',
        // Placement
        'flex',
        'justify-between',
        'items-center',
        // Spacing
        'm-0',
        'p-0',
        // Custom classes
        tabsContainerClasses,
      ]"
      v-bind="listAttributes"
      :role="listRole"
    >
      <template v-for="(tab, index) in tabs">
        <li :key="tab.key" :ref="(el) => setTabRef(el, index, tab)" :role="listItemRole">
          <OzPlainButton
            ref="tabButtonElsRef"
            :data-testid="tab.testId"
            :class="[
              'flex items-center group',
              'outline-none',
              sizePreset == OzTabsWithSeparatorSizePreset.H28px && [
                'h-[1.75rem]',
                'rounded-[10px]',
                'px-3',
                'text-body-small',
              ],
              sizePreset == OzTabsWithSeparatorSizePreset.H37px && [
                'h-[2.3125rem]',
                'rounded-xl',
                'px-2',
                'text-body-small',
              ],
              sizePreset == OzTabsWithSeparatorSizePreset.H40px && ['h-10', 'rounded-2xl', 'px-2', 'text-heading-4'],
              sizePreset == OzTabsWithSeparatorSizePreset.H42px && [
                'h-[2.625rem]',
                'rounded-2xl',
                'px-2',
                'text-heading-3',
              ],
              sizePreset == OzTabsWithSeparatorSizePreset.H48px && ['h-12', 'rounded-2xl', 'px-2', 'text-heading-2'],
              sizePreset == OzTabsWithSeparatorSizePreset.H59px && [
                'h-[3.6875rem]',
                'rounded-2.5xl',
                'px-3',
                'text-heading-1',
              ],
              // Font
              'font-semibold',
              // Base
              currentTabId === tab.key && [
                highContrastSelectedTabUnderlineClasses,
                selectedTextColorClasses || {
                  'text-grape-500': darkMode === false,
                  'text-canary-500': darkMode === true,
                  'text-grape-500 dark:text-canary-500': darkMode === 'auto',
                },
              ],
              currentTabId !== tab.key && [
                nonSelectedTextColorClasses || [
                  tab.colorPreset == null && {
                    'text-dark-text-200': darkMode === false,
                    'text-light-text-200': darkMode === true,
                    'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
                  },
                  tab.colorPreset === OzTabsWithSeparatorButtonColorPreset.Primary && {
                    'text-padlet-pink': !tab.disabled,
                    '!text-padlet-pink-100': tab.disabled,
                  },
                ],
              ],
              // Hovered
              !tab.disabled && {
                'hhover:text-dark-text-100': darkMode === false,
                'hhover:text-light-text-100': darkMode === true,
                'hhover:text-dark-text-100 dark:hhover:text-light-text-100': darkMode === 'auto',
              },
              // Focused
              'ring-inset',
              sizePreset == OzTabsWithSeparatorSizePreset.H28px && 'focus-visible:ring-2',
              sizePreset == OzTabsWithSeparatorSizePreset.H37px && 'focus-visible:ring-2',
              sizePreset == OzTabsWithSeparatorSizePreset.H40px && 'focus-visible:ring-[2.5px]',
              sizePreset == OzTabsWithSeparatorSizePreset.H42px && 'focus-visible:ring-[2.5px]',
              sizePreset == OzTabsWithSeparatorSizePreset.H48px && 'focus-visible:ring-[2.5px]',
              sizePreset == OzTabsWithSeparatorSizePreset.H59px && 'focus-visible:ring-4',
              {
                'focus-visible:ring-grape-500': darkMode === false,
                'focus-visible:ring-canary-500': darkMode === true,
                'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': darkMode === 'auto',
              },
              // Active
              !tab.disabled && {
                'hactive:text-grape-500': darkMode === false,
                'hactive:text-canary-500': darkMode === true,
                'hactive:text-grape-500 hactive:dark:text-canary-500': darkMode === 'auto',
              },
              // Custom classes
              !tab.disabled && nonDisabledButtonClasses,
            ]"
            v-bind="computeTabAttributes(tab)"
            :href="tab.href"
            :href-mode="tab.hrefMode"
            :size-preset="OzPlainButtonSizePreset.Bare"
            :color-scheme="OzPlainButtonColorScheme.Bare"
            :dark-mode="darkMode"
            :disabled="tab.disabled"
            :x-title="tab.xTitleTooltip"
            :aria-labelledby="tab.xTitleTooltip === false ? `custom-tooltip-${index}` : undefined"
            @click="emit('activate-tab', $event, { key: tab.key })"
          >
            <div class="flex-1 truncate align-middle">
              <span v-if="tab.startAdornmentText" class="me-0.75" :aria-hidden="true">{{ tab.startAdornmentText }}</span
              ><span>{{ tab.text }}</span>
            </div>
            <span v-if="tab.xTitleTooltip === false" :id="`custom-tooltip-${index}`" class="sr-only">
              {{ tab.tooltipText }}
            </span>
            <OzTooltip
              v-if="tab.tooltipText && tabButtonElsRef[index]"
              class="hidden hover-hover:group-hover:block w-max"
              :anchor="tabButtonElsRef[index].$el"
              :config="{
                placement: 'bottom-start',
              }"
            >
              {{ tab.tooltipText }}
            </OzTooltip>
          </OzPlainButton>
        </li>
        <li
          v-if="index !== tabs.length - 1"
          :key="`${tab.key}-divider`"
          :class="[
            sizePreset == OzTabsWithSeparatorSizePreset.H28px && 'text-body',
            sizePreset == OzTabsWithSeparatorSizePreset.H37px && 'text-body',
            sizePreset == OzTabsWithSeparatorSizePreset.H40px && 'text-body',
            sizePreset == OzTabsWithSeparatorSizePreset.H42px && 'text-body-large',
            sizePreset == OzTabsWithSeparatorSizePreset.H48px && 'text-body-large',
            sizePreset == OzTabsWithSeparatorSizePreset.H59px && 'text-heading-1',
            'select-none',
            {
              'text-dark-text-400': darkMode === false,
              'text-light-text-400': darkMode === true,
              'text-dark-text-400 dark:text-light-text-400': darkMode === 'auto',
            },
          ]"
          aria-hidden="true"
          tabindex="-1"
        >
          /
        </li>
      </template>
    </ul>
  </component>
</template>
